@import '../../utilities/lib.less';
.flex {
	&.horizontal {
		> .itemBox {
			padding-right: 5px;
		}

		&.buttonsWithOptions {
			margin-bottom: -8px;
			.itemBox {
				margin-bottom: 8px;
			}
		}
	}

	&.vertical > .itemBox {
		> .bas-inputButton {
			margin-top: 5px;
		}

		&.first > .bas-inputButton {
			margin-top: 0;
		}
	}

	&.linkGroup {
		margin: 5px 0;
	}
}

.noLabel .flex.horizontal > .itemBox {
	max-width: 720px;
}

.noLabel .flex.horizontal.column160 > .itemBox {
	max-width: 160px;
}
