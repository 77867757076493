@import '../../utilities/lib.less';

.flex .item.horizontal {
	display: flex;
}

.itemBox {
	overflow: visible;
	float: none;
}

.item {

	&.prev-container>.itemBox,
	&.next-container>.itemBox {
		vertical-align: middle;
	}

	&.horizontal {
		>.itemBox {
			padding-right: 5px;
		}

		&.buttonsWithOptions {
			margin-bottom: -8px;

			.itemBox {
				margin-bottom: 8px;
			}
		}
	}

	&.vertical>.itemBox {
		>.bas-inputButton {
			margin-top: 5px;
		}

		&.first>.bas-inputButton {
			margin-top: 0;
		}
	}

	&.linkGroup {
		margin: 5px 0;
	}
}

.noLabel .item.horizontal>.itemBox {
	max-width: 720px;
}

.buttonline {
	padding: 10px 0 10px 0;
	border: 0 none;
	margin-top: -5px;

	.bas-inputButton {
		display: inline-block;
		margin-top: 5px;
	}

	&.item {
		padding: 10px 0;
	}

	.buttonContainer {
		display: inline-block;
	}

	&.top {
		padding: 0;
		border-top: 1px solid @HellGrau30;
		border-bottom: none;

		.ui-datepicker-trigger {
			margin-right: 10px;
		}
	}

	&.field {
		border: none;
		padding: 30px 0 10px 0;
	}
}

div.item.buttonline.top>* {
	margin-right: 20px;
}

#editMask+.contentContainer .item.linkGroup {
	margin: -6px 0 25px 0;
}