@import '../../utilities/lib.less';
.defaultLink.icon , div.icon {
	background-repeat: no-repeat;
	background-position: 0 0;
	display: inline-block;
	padding-left: 0;
	line-height: 26px;
	margin-right: 10px;
	cursor: pointer;
	&.inactive {
		cursor: default;
	}
}

.itemBox > .icon {
	margin-right: 0;
}

.icon, .noIcon {
	width: 26px;
	height: 26px;
}

.inactive {
	cursor: default;
}

.defaultLink.icon {

	&.resetFilter {
		padding-right: 0;
		background-image: url("../../../img/icons/edit/filter-reset-btn.png");
		&:hover , &:focus {
			background-image: url("../../../img/icons/edit/filter-reset-btn-rollover.png");
		}
	}

	&.rename , &.edit_small , &.edit , &.edit_external {
		background-image: url("../../../img/btn/bearbeiten.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/bearbeiten-rollover.png");
		}
	}

	&.favorite {
		background-image: url("../../../img/btn/favorite.png");

		&:hover, &:focus {
			background-image: url("../../../img/btn/favorite-rollover.png");
		}
	}

	&.favorite_choosen {
		background-image: url("../../../img/btn/favorite-choosen.png");

		&:hover, &:focus {
			background-image: url("../../../img/btn/favorite-choosen-rollover.png");
		}
	}

	&.edit_external {
		background-image: url("../../../img/btn/correction_external_site.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/correction_external_site_hover.png");
		}
	}

	&.edit_small_metadata {
		background-image: url("../../../img/btn/metadaten_editieren.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/metadaten_editieren_rollover.png");
		}

		&.inactive , &_inactive {
			background-image: url("../../../img/btn/metadaten_editieren_inaktiv.png");
		}
	}

	&.copy_small , &.copy {
		background-image: url("../../../img/btn/kopieren.png");
		&:hover {
			background-image: url("../../../img/btn/kopieren-rollover.png");
		}
	}

	&.lock_button_inactive {
		background-image: url("../../../img/btn/verschlossen-inaktiv.png");
		&:hover {
			background-image: url("../../../img/btn/verschlossen-inaktiv.png");
		}
	}

	&.lock_small , &.lock_button {
		background-image: url("../../../img/btn/verschlossen.png");
		&:hover {
			background-image: url("../../../img/btn/verschlossen-rollover.png");
		}
	}

	&.unlock_button_inactive {
		background-image: url("../../../img/btn/unverschlossen-inaktiv.png");
		&:hover {
			background-image: url("../../../img/btn/unverschlossen-inaktiv.png");
		}
	}

	&.unlock_small , &.unlock_button {
		background-image: url("../../../img/btn/unverschlossen.png");
		&:hover {
			background-image: url("../../../img/btn/unverschlossen-rollover.png");
		}
	}

	&.visible_small  {
		background-image: url("../../../img/btn/sichtbar.png");
		&:hover {
			background-image: url("../../../img/btn/sichtbar-rollover.png");
		}
	}

	&.invisible_small  {
		background-image: url("../../../img/btn/nicht-sichtbar.png");
		&:hover {
			background-image: url("../../../img/btn/nicht-sichtbar-rollover.png");
		}
	}


	&.assignment_small {
		background-image: url("../../../img/btn/zuordnen.png");
		&:hover {
			background-image: url("../../../img/btn/zuordnen-rollover.png");
		}
	}

	&.delete , &.delete_small {
		background-image: url("../../../img/btn/loeschen.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/loeschen-rollover.png");
		}
	}

	&.show , &.show_small {
		background-image: url("../../../img/btn/vorschau.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/vorschau-rollover.png");
		}
	}

	&.add , &.new {
		background-image: url("../../../img/btn/neu-dokument.png");
		&:hover {
			background-image: url("../../../img/btn/neu-dokument-rollover.png");
		}
	}

	&.add_external {
		background-image: url("../../../img/btn/neu-dokument-extern.png");
		&:hover {
			background-image: url("../../../img/btn/neu-dokument-extern-rollover.png");
		}
	}

	&.add , &.new {
		background-image: url("../../../img/btn/neu-dokument.png");
		&:hover {
			background-image: url("../../../img/btn/neu-dokument-rollover.png");
		}
	}

	&.cut {
		background-image: url("../../../img/btn/ausschneiden.png");
		&:hover {
			background-image: url("../../../img/btn/ausschneiden-rollover.png");
		}
	}

	&.paste {
		background-image: url("../../../img/btn/einfuegen.png");
		&:hover {
			background-image: url("../../../img/btn/einfuegen-rollover.png");
		}
	}

	&.openAll, &.addNew {
		background-image: url("../../../img/btn/aufklappen.png");
		&:hover {
			background-image: url("../../../img/btn/aufklappen-rollover.png");
		}
	}

	&.closeAll {
		background-image: url("../../../img/btn/zuklappen.png");
		&:hover {
			background-image: url("../../../img/btn/zuklappen-rollover.png");
		}
	}

	&.process {
		background-image: url("../../../img/kalender/vor.png");
		&:hover {
			background-image: url("../../../img/kalender/vor-rollover.png");
		}
	}

	&.pricelist {
		float: left;
		background-image: url("../../../img/btn/preisliste.png");
		&:hover {
			background-image: url("../../../img/btn/preisliste_rollover.png");
		}
	}

	&.take_small , .take {
		background-image: url("../../../img/btn/uebernehmen.png");
		&:hover {
			background-image: url("../../../img/btn/uebernehmen-rollover.png");
		}
	}

	&.expand_small {
		background-image: url("../../../img/btn/aufklappen.png");
		&:hover {
			background-image: url("../../../img/btn/aufklappen-rollover.png");
		}
	}

	&.collapse_small {
		background-image: url("../../../img/btn/zuklappen.png");
		&:hover {
			background-image: url("../../../img/btn/zuklappen-rollover.png");
		}
	}

	&.aoz_neu {
		background-image: url("../../../img/icons/icon_aoz_neu.png");
		&:hover , &.selected {
			background-image: url("../../../img/icons/icon_aoz_neu_hover.png");
		}
	}

	&.aoz_aenderungen {
		background-image: url("../../../img/icons/icon_aoz_aenderungen.png");
		&:hover , &.selected {
			background-image: url("../../../img/icons/icon_aoz_aenderungen_hover.png");
		}
	}

	&.aoz_abgelaufen {
		background-image: url("../../../img/icons/icon_aoz_abgelaufen.png");
		&:hover , &.selected {
			background-image: url("../../../img/icons/icon_aoz_abgelaufen_hover.png");
		}
	}

	&.aoz_status {
		background-image: url("../../../img/icons/icon_aoz_status.png");
		&:hover , &.selected {
			background-image: url("../../../img/icons/icon_aoz_status_hover.png");
		}
	}

	&.aoz_alle {
		background-image: url("../../../img/icons/icon_aoz_alle.png");
		&:hover , &.selected {
			background-image: url("../../../img/icons/icon_aoz_alle_hover.png");
		}
	}

	&.info {
		background-image: url("../../../img/icons/info.png");
		&:hover {
			background-image: url("../../../img/icons/info-rollover.png");
		}
	}

	&.move_first {
		background-image: url("../../../img/btn/move-top.png");
		&:hover {
			background-image: url("../../../img/btn/move-top-rollover.png");
		}
	}

	&.move_up , &.move_up_small {
		background-image: url("../../../img/btn/move-up.png");
		&:hover {
			background-image: url("../../../img/btn/move-up-rollover.png");
		}
	}

	&.move_down , &.move_down_small {
		background-image: url("../../../img/btn/move-down.png");
		&:hover {
			background-image: url("../../../img/btn/move-down-rollover.png");
		}
	}

	&.move_last {
		background-image: url("../../../img/btn/move-bottom.png");
		&:hover {
			background-image: url("../../../img/btn/move-bottom-rollover.png");
		}
	}

	&.history {
		background-image: url("../../../img/btn/history.png");
		&:hover {
			background-image: url("../../../img/btn/history-rollover.png");
		}
	}

	&.pricelist , &.pricelist_small {
		background-image: url("../../../img/btn/preisliste.png");
		&:hover {
			background-image: url("../../../img/btn/preisliste_rollover.png");
		}
	}

	&.download {
		background-image: url("../../../img/btn/download.png");
		&:hover {
			background-image: url("../../../img/btn/download-rollover.png");
		}

		&.inactive {
			background-image: url("../../../img/btn/download-inaktiv.png");
		}
	}

	&.download_final {
		background-image: url("../../../img/btn/download-final.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/download-final-rollover.png");
		}

		&_inactive {
			background-image: url("../../../img/btn/download-final-inaktiv.png");
			cursor: default;
		}
	}

	&.download_preview {
		background-image: url("../../../img/btn/download-preview.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/download-preview-rollover.png");
		}

		&_inactive {
			background-image: url("../../../img/btn/download-preview-inaktiv.png");
		}
	}

	&.create_preview {
		background-image: url("../../../img/btn/create-preview.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/create-preview-rollover.png");
		}

		&_inactive {
			background-image: url("../../../img/btn/create-preview-inaktiv.png");
		}
	}

	&.create_final {
		background-image: url("../../../img/btn/create-final.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/create-final-rollover.png");
		}

		&_inactive {
			background-image: url("../../../img/btn/create-final-inaktiv.png");
		}
	}

	&.upload {
		background-image: url("../../../img/btn/upload.png");
		&:hover {
			background-image: url("../../../img/btn/upload-rollover.png");
		}
	}

	&.desktopPreview, &.mobilePreview, &.pdfsuperview.archiveNewsletterPreview, &.pdfsuperview.archiveNewsletterTmPreview {
		width: 20px;
		height: 20px;
		margin-top: 5px;
		margin-right: 5px;
		padding-right: 0;
		background-size: 20px;
	}

	&.desktopPreview {
		background-image: url("../../../img/icons/icon_desktop_black.png");
		&:hover, &:focus {
			background-image: url("../../../img/icons/icon_desktop_red.png");
		}
	}

	&.mobilePreview {
		background-image: url("../../../img/icons/icon_mobile_black.png");
		&:hover, &:focus {
			background-image: url("../../../img/icons/icon_mobile_red.png");
		}
	}

	&.pdfsuperview.archiveNewsletterPreview, &.pdfsuperview.archiveNewsletterTmPreview {
		background-image: url("../../../img/icons/icon_vorschau_black.png");
		&:hover, &:focus {
			background-image: url("../../../img/icons/icon_vorschau_red.png");
		}
	}
}

.icon {
	&.rename_inactive , &.rename.inactive , &.edit.inactive , &.edit_small_inactive.inactive , &.edit_external_inactive.inactive , &.edit_small.inactive {
		background-image: url("../../../img/btn/bearbeiten-inaktiv.png");
		cursor: default;
	}

	&.edit_external_inactive.inactive {
		background-image: url("../../../img/btn/correction_external_site_inactive.png");
		cursor: default;
	}

	&.copy_inactive.inactive , &.copy_small_inactive.inactive , &.copy_small.inactive {
		background-image: url("../../../img/btn/kopieren-inaktiv.png");
	}

	&.lock_small_inactive.inactive {
		background-image: url("../../../img/btn/verschlossen-inaktiv.png");
	}

	&.unlock_small_inactive.inactive {
		background-image: url("../../../img/btn/unverschlossen-inaktiv.png");
	}

	&.assignment_small_inactive.inactive {
		background-image: url("../../../img/btn/zuordnen-inaktiv.png");
	}

	&.contactEdit , &.contactEdit_small {
		background-image: url("../../../img/btn/laenderkonfig_kontakt_bearbeiten.png");
		&:hover {
			background-image: url("../../../img/btn/laenderkonfig_kontakt_bearbeiten_hover.png");
		}
	}

	&.contactEdit_small_inactive , &.contactEdit_inactive.inactive , &.contactEdit_small.inactive , &.contactEdit_inactive {
		background-image: url("../../../img/btn/laenderkonfig_kontakt_bearbeiten_inactiv.png");
	}

	&.languages , &.languages_small {
		background-image: url("../../../img/btn/laenderkonfig_sprache_bearbeiten.png");
		&:hover {
			background-image: url("../../../img/btn/laenderkonfig_sprache_bearbeiten_hover.png");
		}
	}

	&.languages_small_inactive , &.languages_inactive.inactive , &.languages_small.inactive , &.languages_inactive {
		background-image: url("../../../img/btn/laenderkonfig_sprache_bearbeiten_inactiv.png");
	}

	&.assignment_small_inactive , &.assignment_inactive.inactive , &.assignment_small.inactive , &.assignment_inactive {
		background-image: url("../../../img/btn/zuordnen-inaktiv.png");
	}

	&.delete_inactive , &.delete_small_inactive {
		background-image: url("../../../img/btn/loeschen-inaktiv.png");
		cursor: default;
	}

	&.show_small_inactive , &.show_inactive.inactive , &.show_small.inactive {
		background-image: url("../../../img/btn/vorschau-inaktiv.png");
	}

	&.add_inactive.inactive , &.new_inactive.inactive {
		background-image: url("../../../img/btn/neu-dokument-inaktiv.png");
	}

	&.cut_inactive.inactive {
		background-image: url("../../../img/btn/ausschneiden-inaktiv.png");
	}

	&.paste_inactive.inactive {
		background-image: url("../../../img/btn/einfuegen-inaktiv.png");
	}

	&.openAll_inactive.inactive {
		background-image: url("../../../img/btn/aufklappen-inaktiv.png");
	}

	&.closeAll_inactive.inactive {
		background-image: url("../../../img/btn/zuklappen-inaktiv.png");
	}

	&.take_small_inactive {
		background-image: url("../../../img/btn/uebernehmen-inaktiv.png");
	}

	&.move_first_inactive {
		background-image: url("../../../img/btn/move-top-inactive.png");
	}

	&.move_last_inactive {
		background-image: url("../../../img/btn/move-bottom-inactive.png");
	}

	&.pricelist , &.pricelist_small {
		background-image: url("../../../img/btn/preisliste.png");
		&:hover {
			background-image: url("../../../img/btn/preisliste_rollover.png");
		}
	}

	&.pricelist_inactive , &.pricelist_small_inactive {
		background-image: url("../../../img/btn/preisliste_inaktiv.png");
	}

	&.help_small {
		background-image: url("../../../img/icon_help_small.gif");
		&:hover {
			background-image: url("../../../img/icon_help_small_mouseover.gif");
		}
	}

	&.download_inactive {
		background-image: url("../../../img/btn/download-inaktiv.png");
	}

	&.moveUp {
		background-image: url("../../../img/btn/move-up.png");
		&:hover {
			background-image: url("../../../img/btn/move-up-rollover.png");
		}

		&.inactive {
			background-image: url("../../../img/btn/move-up-inactive.png");
		}
	}

	&.moveDown {
		background-image: url("../../../img/btn/move-down.png");
		&:hover {
			background-image: url("../../../img/btn/move-down-rollover.png");
		}

		&.inactive {
			background-image: url("../../../img/btn/move-down-inactive.png");
		}
	}

	&.prev{
		background-image: url("../../../img/btn/move-down.png");
		&:hover {
			background-image: url("../../../img/btn/move-down-rollover.png");
		}

		&.inactive {
			background-image: url("../../../img/btn/move-down-inactive.png");
		}
		.rotate(90deg);
		padding-right: 0;
		display: block;
	}

	&.next{
		background-image: url("../../../img/btn/move-down.png");
		&:hover {
			background-image: url("../../../img/btn/move-down-rollover.png");
		}

		&.inactive {
			background-image: url("../../../img/btn/move-down-inactive.png");
		}
		.rotate(270deg);
		padding-right: 0;
		display: block;
	}

	&.trash_bin_small {
		background-image: url("../../../img/btn/loeschen.png");
		&:hover , &:focus {
			background-image: url("../../../img/btn/loeschen-rollover.png");
		}
	}

	&.controls {
		&.move_up_inactive {
			background-image: url("../../../img/jstree/blaetterpfeil-passiv-oben.png");
		}

		&.move_down_inactive {
			background-image: url("../../../img/jstree/blaetterpfeil-passiv-unten.png");
		}

		&.move_left_inactive, &.moveLeft.inactive {
			background-image: url("../../../img/jstree/blaetterpfeil-passiv-links.png");
		}

		&.move_right_inactive, &.moveRight.inactive {
			background-image: url("../../../img/jstree/blaetterpfeil-passiv-rechts.png");
		}

		&.defaultLink {
			&.moveUp {
				background-image: url("../../../img/jstree/blaetterpfeil-aktiv-oben.png");
				&:hover {
					background-image: url("../../../img/jstree/blaetterpfeil-rollover-oben.png");
				}
			}

			&.moveDown {
				background-image: url("../../../img/jstree/blaetterpfeil-aktiv-unten.png");
				&:hover {
					background-image: url("../../../img/jstree/blaetterpfeil-rollover-unten.png");
				}
			}

			&.moveLeft {
				background-image: url("../../../img/jstree/blaetterpfeil-aktiv-links.png");
				&:hover {
					background-image: url("../../../img/jstree/blaetterpfeil-rollover-links.png");
				}
			}

			&.moveRight {
				background-image: url("../../../img/jstree/blaetterpfeil-aktiv-rechts.png");
				&:hover {
					background-image: url("../../../img/jstree/blaetterpfeil-rollover-rechts.png");
				}
			}
		}
	}

	&.state {
		padding-right: 0;
	}

	&.bookable {
		background-image: url("../../../img/mtpage_complete.png");
	}

	&.notBookable {
		background-image: url("../../../img/mtpage_error.png");
	}

	&.clockpicker-trigger {
		padding-right: 0;
		background-image: url("../../../img/btn/clockpicker_normal.png");
		&:hover {
			background-image: url("../../../img/btn/clockpicker_hover.png");
		}

		&.inactive {
			background-image: url("../../../img/btn/clockpicker_inactive.png");
		}
	}

	&.favIcon {
		svg.favorite_star {
			width: 15px;
		}

		&.isFavorite {
			svg.favorite_star {
				.outer , .inner {
					fill: @AudiRot;
				}
			}

			&:hover svg.favorite_star {
				.outer {
					fill: @AudiSchwarz;
				}

				.inner {
					fill: transparent;
				}
			}

			&.manually {
				& , &:hover {
					svg.favorite_star {
						.outer , .inner {
							fill: @AudiRot;
						}
					}
				}
			}
		}
	}
}

html.ie11 .icon.favIcon svg.favorite_star {
	margin-top: -2px;
}

.icon.move_up_inactive , .icon.move_up_small_inactive.inactive , .orderChangeButtons .icon.move_up_inactive {
	background-image: url("../../../img/btn/move-up-inactive.png");
}

.icon.move_down_inactive , .icon.move_down_small_inactive.inactive , .orderChangeButtons .icon.move_down_inactive {
	background-image: url("../../../img/btn/move-down-inactive.png");
}

div.profilerNeededWithText {
	padding-left: 25px;
}

div.profilerNeeded , div.profilerNeededWithText {
	background: url("../../../img/icons/redTriangleWithExclamationMark.png") 0 0 no-repeat;
	display: inline-block;
}

div.infoForUser {
	background: url("../../../img/icons/info_small.png") 0 0 no-repeat;
	width: 17px;
	height: 17px;
	&:hover {
		background-image: url("../../../img/icons/info_small-rollover.png")
	}
}

div.profilerNeeded {
	width: 17px;
	height: 15px;
}

#archive_delete {
	padding: 0;
}

.controls {
	&.moveDown , &.moveUp , &.moveLeft , &.moveRight , &.move_down_inactive.text , &.move_up_inactive.text , &.move_left_inactive.text , &.move_right_inactive.text {
		padding: 0;
		outline: 0 none;
	}

	&.moveDown , &.moveUp , &.move_down_inactive.text , &.move_up_inactive.text , &.move_down_inactive.icon , &.move_up_inactive.icon {
		margin-left: 85px;
		width: 26px;
		height: 30px;
	}

	&.moveLeft , &.moveRight , &.move_left_inactive.text , &.move_right_inactive.text , &.move_left_inactive.icon , &.move_right_inactive.icon {
		width: 30px;
		height: 26px;
	}

	&.moveLeft , &.move_left_inactive.text {
		float: right;
		margin-right: 30px;
		padding-left: 0;
	}

	&.moveRight , &.move_right_inactive.text {
		margin-left: 11px;
	}
}

img.beraterState {
	height: 14px;
	width: 14px;
}

.statusIcon {
	margin-right: 5px;
}

.lightboxLinkInactive {
	display: none;
}

.buttonOption.icon {
	background-image: none;
	padding: 5px;
	.iconContainer {
		width: 26px;
		height: 26px;
		margin-right: 5px;
		background-color: @PrimaryFontColor;
	}

	&:hover {
		color: @InteractiveBackgroundColorHighlight;
		.iconContainer {
			background-color: @InteractiveBackgroundColorHighlight;
		}
	}

	&.delete , &.edit , &.take {
		background-image: none;
	}

	&.delete .iconContainer {
		background-image: url("../../../img/btn/btnOption_delete.png");
	}

	&.edit .iconContainer {
		background-image: url("../../../img/btn/btnOption_edit.png");
	}

	&.take .iconContainer {
		background-image: url("../../../img/btn/btnOption_take.png");
	}
}

#favoriteMark ~ div.checkbox.favoriteMark {
	background-image: url("../../../img/icons/star_icon_sprite.png");
	background-position: 0 0;
	cursor: pointer;
	width: 15px;
	height: 15px;
	padding: 0;
	margin: 3px 0 0 0;
	outline: 0;
	display: block;
	&:hover {
		background-position: -15px 0;
	}

	&.checked {
		background-position: -30px 0;
		&:hover {
			background-position: 0 0;
		}
	}
}
.selectionGroup {
	.selection input {
		@Path_2020-Vehicle_With_Offer: "@{Path_To_Selections_Folder}teaser_newsletter_article_selection_2020-vehicle_with_offer";
		@Path_2020-Picture_With_Text: "@{Path_To_Selections_Folder}teaser_newsletter_article_selection_2020-picture_with_text";
		@Path_2020-Offers_With_Pictures: "@{Path_To_Selections_Folder}teaser_newsletter_article_selection_2020-offers_with_pictures";
		@Path_2020-Tyre_Offers: "@{Path_To_Selections_Folder}teaser_newsletter_article_selection_2020-tyre_offers";
		@Path_2020-Table: "@{Path_To_Selections_Folder}teaser_newsletter_article_selection_2020-table";
		@Path_2020-Boerse: "@{Path_To_Selections_Folder}teaser_newsletter_article_selection_2020-boerse";
		@Path_Teaser: "@{Path_To_Selections_Folder}teaser_newsletter_article_selection_teaser";
		
		+ label.selectionArea {
			background-position: 0 0;
			background-color: @AudiWeiss;
		}

		+ label.selectionArea , &:disabled:checked + label.selectionArea {
			&.layout_2020-vehicle_with_offer {
				background-image: url("@{Path_2020-Vehicle_With_Offer}.png");
			}

			&.layout_2020-picture_with_text {
				background-image: url("@{Path_2020-Picture_With_Text}.png");
			}
			
			&.layout_2020-offers_with_pictures {
				background-image: url("@{Path_2020-Offers_With_Pictures}.png");
			}
			
			&.layout_2020-tyre_offers {
				background-image: url("@{Path_2020-Tyre_Offers}.png");
			}
			
			&.layout_2020-table {
				background-image: url("@{Path_2020-Table}.png");
			}

			&.layout_teaser {
				background-image: url("@{Path_Teaser}.png");
			}
			
			&.layout_2020-boerse {
				background-image: url("@{Path_2020-Boerse}.png");
			}
		}

		+ label.selectionArea:hover , &:checked + label.selectionArea {
			&.layout_2020-vehicle_with_offer {
				background-image: url("@{Path_2020-Vehicle_With_Offer}_hover.png");
			}

			&.layout_2020-picture_with_text {
				background-image: url("@{Path_2020-Picture_With_Text}_hover.png");
			}
			
			&.layout_2020-offers_with_pictures {
				background-image: url("@{Path_2020-Offers_With_Pictures}_hover.png");
			}
			
			&.layout_2020-tyre_offers {
				background-image: url("@{Path_2020-Tyre_Offers}_hover.png");
			}
			
			&.layout_2020-table {
				background-image: url("@{Path_2020-Table}_hover.png");
			}

			&.layout_teaser {
				background-image: url("@{Path_Teaser}_hover.png");
			}
			
			&.layout_2020-boerse {
				background-image: url("@{Path_2020-Boerse}_hover.png");
			}
		}

		&:disabled + label.selectionArea {
			&.layout_2020-vehicle_with_offer {
				background-image: url("@{Path_2020-Vehicle_With_Offer}_disabled.png");
			}

			&.layout_2020-picture_with_text {
				background-image: url("@{Path_2020-Picture_With_Text}_disabled.png");
			}

			&.layout_2020-offers_with_pictures {
				background-image: url("@{Path_2020-Offers_With_Pictures}_disabled.png");
			}
			
			&.layout_2020-tyre_offers {
				background-image: url("@{Path_2020-Tyre_Offers}_disabled.png");
			}
			
			&.layout_2020-table {
				background-image: url("@{Path_2020-Table}_disabled.png");
			}
			
			&.layout_teaser {
				background-image: url("@{Path_Teaser}_disabled.png");
			}

			&.layout_2020-boerse {
				background-image: url("@{Path_2020-Boerse}_disabled.png");
			}
			
			svg{
				&#db-image, &#upload-image, &#default-image, &#db-image:hover, &#upload-image:hover, &#default-image:hover {
					.stroke {
						stroke: @InteractiveBackgroundColorInactive;
					}
				}

				&#no-image, &#no-image:hover {
					.stroke, .picture, .circle{
						stroke: @InteractiveBackgroundColorInactive;
					}
				}
			}
		}
	}
}
